import React, { FC } from "react";
import { Link, graphql } from "gatsby";
import { VacancyQuery, AppliedQuery } from "../graphql-types";
import BackgroundImage from "gatsby-background-image";
import { FluidObject, FixedObject } from "gatsby-image";
import { Logo } from "../components/logo";
import { colors, fontStacks } from "../global-style";
import { Button } from "../components/button";
import { Center } from "../components/center";
import { Form } from "../components/form";
import Img from "gatsby-image";

export const query = graphql`
  query Applied {
    heroBackground: file(
      relativePath: { eq: "baked-bakery-bread-205961_1.png" }
    ) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bread: file(relativePath: { eq: "bread.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 500) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    cake: file(relativePath: { eq: "cake.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 110) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    croissant: file(relativePath: { eq: "croissant.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 170) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    slicedBread: file(relativePath: { eq: "sliced-bread.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const VacancyPage: FC<{
  data: AppliedQuery;
  location: { href: string };
}> = ({ data, location: { href } }) => {
  return (
    <>
      <BackgroundImage
        Tag="header"
        style={{
          minHeight: "30vh",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
        fluid={[
          "linear-gradient(rgba(20, 15, 0, 0.47), rgba(20, 15, 0, 0.47))",
          data.heroBackground.childImageSharp.fluid as FluidObject,
        ]}
      >
        <div
          style={{ marginTop: "0.5rem" }}
          css={`
            @media (max-width: 50rem) {
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 1rem;
              transform: scale(0.9);
            }
          `}
        >
          <Logo />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "3rem",
            textAlign: "center",
          }}
        >
          <Link to="/">
            <Button style={{ marginBottom: "1.5rem" }}>
              &larr; Alle vacatures
            </Button>
          </Link>

          <h1 style={{ maxWidth: "40ch", color: colors.white }}>
            Bedankt voor je sollicitatie
          </h1>
        </div>
      </BackgroundImage>

      <main
        style={{
          backgroundColor: colors.iceGrey,
          paddingBottom: "1rem",
        }}
      >
        <Center
          hasGutters={false}
          style={{
            position: "relative",
            top: "-1.5rem",
            boxShadow: "0px 5px 20px 0px rgba(0,0,0,0.05)",
          }}
        >
          <Img
            style={{
              position: "absolute",
              zIndex: 1,
              top: "20%",
              left: "-12rem",
            }}
            fixed={data.croissant.childImageSharp.fixed as FixedObject}
          />

          <Img
            style={{
              position: "absolute",
              zIndex: 1,
              top: "30%",
              right: "-30rem",
            }}
            fixed={data.bread.childImageSharp.fixed as FixedObject}
          />

          <section
            style={{
              backgroundColor: colors.white,
              paddingTop: "2rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingBottom: "3rem",
              minHeight: 300,
            }}
          >
            <div style={{ overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "-0.75rem",
                }}
              >
                <div
                  style={{
                    flexBasis: "40ch",
                    flexGrow: 999,
                    margin: "0.75rem",
                  }}
                >
                  <h2 style={{ marginBottom: "0.75rem" }}>
                    Sollicitatie ontvangen
                  </h2>

                  <p style={{ marginBottom: "0.75rem" }}>
                    We hebben je sollicitatie succesvol ontvangen.
                  </p>

                  <p>
                    Neem ook een kijkje bij de{" "}
                    <Link to="/" style={{ textDecoration: "underline" }}>
                      overige vacatures
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Center>
      </main>

      <div
        dangerouslySetInnerHTML={{
          __html: `<!-- Begin INDEED conversion code -->
<script type="text/javascript">
/* <![CDATA[ */
var indeed_conversion_id = '7276623105756326';
var indeed_conversion_label = '';
/* ]]> */
</script>
<script type="text/javascript" src="//conv.indeed.com/applyconversion.js">
</script>
<noscript>
<img height=1 width=1 border=0 src="//conv.indeed.com/pagead/conv/7276623105756326/?script=0">
</noscript>
<!-- End INDEED conversion code -->`,
        }}
      />
    </>
  );
};

export default VacancyPage;
